<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>批次名称：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.batch_no" filterable clearable>
                        <el-option v-for="item in batch_no_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>招生老师：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.teacher_no" placeholder="请输入招生老师的学工号"></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>模糊搜索：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.key_words" placeholder="自定义字段模糊查询"></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>状态：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.admission_status" clearable>
                        <el-option v-for="item in admission_status_list" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>报名时间：</span>
                </div>
                <div class="baseSearchOneRight2">
                    <!-- {{ searchForm.start_end_time }} -->
                    <el-date-picker v-model="searchForm.start_end_time" type="daterange" unlink-panels
                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                    value-format="YYYY-MM-DD" :clearable="false"></el-date-picker>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <el-tabs v-model="searchForm.is_pre_inscription" @tab-click="handleClick">
            <el-tab-pane v-for="item in is_pre_inscription_list" :key="item.value" :label="item.label"
                         :name="item.value"></el-tab-pane>
        </el-tabs>
        <div class="baseOperate clearfloat">
            <template v-if="authorityStatus.luqu.show">
                <div class="baseOperateOne2">
                    <span class="baseOperateText">已选 <strong
                        style="color: rgba(65, 148, 242, 1);">{{this.tableDataSel.length}}</strong> 位用户</span>
                </div>
                <div class="baseOperateOne2" v-if="searchForm.is_pre_inscription == 0">
                    <div class="baseOperateOne userTeacherML">
                        <el-button type="warning" @click="openAdmitted('sel',2)">拒绝录取</el-button>
                    </div>
                    <div class="baseOperateOne userTeacherML">
                        <el-button type="success" @click="openAdmitted('sel',1)">选中录取</el-button>
                    </div>
                </div>
                <div class="baseOperateOne2" v-if="searchForm.is_pre_inscription == 1">
                    <div class="baseOperateOne userTeacherML">
                        <el-button type="warning" @click="openAdmitted('sel',2)">拒绝录取</el-button>
                    </div>
                    <div class="baseOperateOne userTeacherML">
                        <el-button type="success" @click="openAdmitted('sel',5)">选中预录取</el-button>
                    </div>
                </div>
<!--                <div class="baseOperateOne userTeacherML">-->
<!--                    <el-button type="danger" @click="linshidaochu">临时导出(只导出当页数据)</el-button>-->
<!--                </div>-->
                <div class="baseOperateOne userTeacherML" v-if="searchForm.is_pre_inscription == 0">
                    <el-button type="success" @click="openAdmitted('all',1)" v-if="authorityStatus.luqu.show">全部录取
                    </el-button>
                </div>
                <div class="baseOperateOne userTeacherML" v-if="searchForm.is_pre_inscription == 1">
                    <el-button type="success" @click="openAdmitted('all',5)" v-if="authorityStatus.luqu.show">全部预录取
                    </el-button>
                </div>
                <div class="baseOperateOne userTeacherML">
                    <el-button type="primary" @click="openExport" v-if="authorityStatus.daochu.show">导出数据</el-button>
                </div>
                <div class="baseOperateOne userTeacherML" v-if="searchForm.batch_no == ''">
                    <el-button @click="dialogVisible = true">更改表头</el-button>
                </div>
                <div class="baseOperateOne">
                    <el-button type="success" @click="openImport">补录学生</el-button>
                </div>
            </template>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         :publicTableConfigure="tableConfigure" @selAllDataFun="tableMsgFromSon($event)"
                         ref="publicTableUserStudent">
                <el-table-column prop="batch_name" label="批次名称"></el-table-column>
                <el-table-column prop="admission_status_name" label="录取状态"></el-table-column>
                <el-table-column prop="teacher_name" label="招生老师"></el-table-column>
                <el-table-column prop="created_at" label="报名时间"></el-table-column>
                <template v-for="item in tableTitle" :key="item.key">
                    <template v-if="item.show">
                        <el-table-column v-if="item.type == 'image'" :label="item.name">
                            <template #default="scope">
                                <el-image v-if="scope.row[item.key+'_image']" style="width: 80px;height: 80px;"
                                          :src="scope.row[item.key+'_image']"
                                          fit="contain" :preview-src-list="[scope.row[item.key+'_image']]"
                                          :z-index="20000"
                                          :append-to-body="true"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column v-else :prop="item.key" :label="item.name"></el-table-column>
                    </template>
                </template>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary" @click.prevent="openEdit(scope.row)"
                                 v-if="authorityStatus.edit.show">
                            编辑
                        </el-link>
                        <el-link class="tableButton" type="warning" @click.prevent="openChangeBatchNo(scope.row)"
                                 v-if="authorityStatus.edit.show && (scope.row.admission_status == 0 || scope.row.admission_status == 1 || scope.row.admission_status == 5 || scope.row.admission_status == 8)">
                            变更批次
                        </el-link>
                        <el-link class="tableButton" type="success" @click.prevent="openChangeLog(scope.row)">
                            变更记录
                        </el-link>
                       <el-link class="tableButton" type="success" @click.prevent="openChangeLog1(scope.row)">
                            操作记录
                        </el-link>
                        <!--  <el-link class="tableButton" type="success" @click.prevent="openChangeLog(scope.row)">
                            备注信息
                        </el-link> -->
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-drawer title="编辑学生信息" v-model="editDialog" :close-on-click-modal="false" :destroy-on-close="true"
                   size="80%">
            <div class="signupProcessDrawerForm">
                <customizeForm ref="customizeForm1" :fieldData="formMsg" :selMsg="selMsg"
                               :labelWidth="'200px'"></customizeForm>
            </div>
            <div class="signupProcessDrawerForm" style="text-align: center;">
                <el-button class="signupPSRightButtonOne" type="primary" size="large" style="width: 160px;"
                           @click="saveEdit">
                    保存
                </el-button>
            </div>
        </el-drawer>
        <el-dialog title="表头显示" v-model="dialogVisible" width="600px">
            <el-form label-width="100px">
                <el-form-item label="表头显示" prop="name">
                    <el-checkbox v-for="item in tableTitle" v-model="item.show" :label="item.name" :key="item.key"
                                 @change="titlesChange"></el-checkbox>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="变更批次" v-model="changeBatchNoShow" width="600px" :close-on-click-modal="false">
            <div class="signupProcessDrawerForm">
                <el-form ref="batchForm" label-width="200px">
                    <el-form-item label="姓名">
                        {{selMsg.name}}
                    </el-form-item>
                    <el-form-item label="原批次名称">
                        {{selMsg.batch_name}}
                    </el-form-item>
                    <el-form-item label="已缴金额">
                        {{selMsg.pay_price}}
                    </el-form-item>
                    <el-form-item>
                        <template v-slot:label>
                            <span style="color: #F56C6C">*</span>
                            <span>新批次名称</span>
                        </template>
                        <el-select v-model="student_batch_no" filterable>
                            <el-option v-for="item in can_batch_list" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <template v-if="new_batch_no_msg != null">
                        <el-form-item label="新批次金额">
                            {{new_batch_no_msg.amount}}
                        </el-form-item>
                        <el-form-item label="" v-if="selMsg.pay_price > 0">
                            <template v-if="new_batch_no_msg.difference == 0">
                                <span>金额无变动</span>
                            </template>
                            <template v-else>
                                <template v-if="new_batch_no_msg.difference>0">
                                    <span>学生需补缴</span>
                                    <strong style="color: #F56C6C">&nbsp;{{new_batch_no_msg.difference}}</strong>
                                    <span>&nbsp;元</span>
                                </template>
                                <template v-else-if="new_batch_no_msg.difference<0">
                                    <span>需给学生退</span>
                                    <strong style="color: #F56C6C">&nbsp;{{new_batch_no_msg.difference * -1}}</strong>
                                    <span>&nbsp;元</span>
                                </template>

                            </template>
                        </el-form-item>
                    </template>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveChangeBatch">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog title="变更记录" v-model="changeLogShow" width="800px" :close-on-click-modal="false">
            <publicTable :publicTableOtherHeight="searchHeight + 50" :publicTableData="changeTableData">
                <el-table-column prop="created_at" label="变更时间" width="110"></el-table-column>
                <el-table-column prop="op_user" label="操作人"></el-table-column>
                <el-table-column prop="before" label="变更前批次名称"></el-table-column>
                <el-table-column prop="before_price" label="变更前缴费金额"></el-table-column>
                <el-table-column prop="after" label="变更后批次名称"></el-table-column>
                <el-table-column prop="after_price" label="变更后缴费金额"></el-table-column>
            </publicTable>
        </el-dialog>
        <el-dialog title="操作记录" v-model="changeLogShow1" width="800px" >
            <publicTable :publicTableOtherHeight="searchHeight + 50" :publicTableData="changeTableData">
                <el-table-column prop="created_at" label="变更时间" ></el-table-column>
                <el-table-column prop="type" label="人员信息"></el-table-column>
                <el-table-column  label="变更内容">
                    <template #default="scope">{{ deName(scope.row.detail)}}</template>
                </el-table-column>
                <el-table-column label="操作人">
                    <template #default="scope">{{ scope.row.user.name }}</template>
                </el-table-column>
            </publicTable>
        </el-dialog>
        <el-dialog title="补录学生" v-model="importStudentShow" width="800px" :close-on-click-modal="false">
            <el-form :model="batchForm" :rules="batchRules" ref="batchForm" label-width="200px">
                <el-form-item label="批量补录学生模板">
                    <a href="./files/批量补录学生模板.xlsx" class="downMuban"
                       style="float: left;" download="批量补录学生模板.xlsx">点击下载模板</a>
                </el-form-item>
                <el-form-item label="上传文件" prop="ruleUploadList">
                    <el-upload style="width: 300px;" ref="importUpload"
                               action="https://jsonplaceholder.typicode.com/posts/"
                               :on-change="ruleHandleChange" :before-remove="ruleHandleChange"
                               :file-list="batchForm.ruleFileList"
                               :auto-upload="false" :on-exceed="ruleHandleExceed"
                               list-type="text" accept=".xlsx" :limit="batchForm.ruleFileLimit">
                        <el-button type="primary">选取文件</el-button>
                        <template #tip>
                            <div class="el-upload__tip">请编辑上一项下载的模板，然后上传</div>
                        </template>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="saveImport">开始导入</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

import publicPage from '../../components/publicPage.vue'

import publicTable from '../../components/publicTable.vue'

import {businessGet, businessPost, businessPut} from '../../api/business.js'

import {utilsExportExcel, utilsGetAuthority, utilsNotificationJump, utilsUploadFile} from '../../utils/utils.js'

import customizeForm from '../../components/customizeForm.vue'

import Global from "../../Global";

import {number, bignumber, subtract} from 'mathjs';

import {ElLoading} from 'element-plus'

import XLSX from "xlsx";
import moment from 'moment'

export default {
    name: "signupStudent",
    components: {
        publicPage,
        publicTable,
        customizeForm
    },
    data() {
        return {
            localName: 'fu_zst_signupStudent_tableTitles',
            allBatchNoMsg: null,
            all_batch_no_list: [],
            batch_no_list: [],
            can_batch_list: [],
            admission_status_list: [],
            is_pre_inscription_list: [
                {
                    value: '1',
                    label: '开启预录取'
                },
                {
                    value: '0',
                    label: '关闭预录取'
                },
            ],
            searchForm: {
                batch_no: '',
                is_pre_inscription: '1',
                teacher_no: '',
                start_end_time: '',
                key_words: '',
                admission_status: '',
            },
            allTitleList: [],
            searchHeight: 0,
            tableConfigure: {
                selection: true,
            },
            tableLoading: false,
            tableTitle: [],
            tableData: [],
            total: 0,
            tableDataSel: [],
            selMsg: null,
            changeLogShow1:false,
            editDialog: false,
            formMsg: [],
            authorityStatus: {
                edit: {
                    method: 'put',
                    url: '/admin/v1/sign_up/archives/update/{id}',
                    show: false,
                },
                luqu: {
                    method: 'put',
                    url: '/admin/v1/sign_up/archives/status',
                    show: false,
                },
                daochu: {
                    method: 'get',
                    url: '/admin/v1/sign_up/archives_export',
                    show: false,
                },
            },
            dialogVisible: false,
            changeBatchNoShow: false,
            student_batch_no: '',
            new_batch_no_msg: null,
            changeLogShow: false,
            changeTableData: [],
            importStudentShow: false,

            batchForm: {
                ruleFileList: [],
                ruleFileLimit: 1,
                ruleUploadList: [],
            },
            batchRules: {
                ruleUploadList: [
                    {type: 'array', required: true, message: '该项不能为空', trigger: 'blur'}
                ],
            },
        }
    },
    watch: {
        student_batch_no() {
            if (this.student_batch_no) {
                let newArray = this.can_batch_list.filter(item => {
                    return item.value == this.student_batch_no
                })
                let new_batch_no_msg = newArray[0];
                new_batch_no_msg.difference = number(subtract(bignumber(new_batch_no_msg.amount), bignumber(this.selMsg.pay_price)));
                this.new_batch_no_msg = new_batch_no_msg;
            }
            else {
                this.new_batch_no_msg = null;
            }
        }
    },
    created() {
        utilsGetAuthority(this.authorityStatus)
        this.getTableTitle();
        this.get_all_batch_no_list();

        this.$nextTick(() => {
            let baseSearchDom = document.querySelector('.baseSearch');
            let baseOperateDom = document.querySelector('.baseOperate');
            let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight + 54;
            this.searchHeight = searchHeight;

        });
        let admission_status_list = [];
        for (let k in Global.Global_admission_status_obj) {
            let one = {
                value: k,
                label: Global.Global_admission_status_obj[k]
            }
            admission_status_list.push(one)
        }
        this.admission_status_list = admission_status_list;
    },
    methods: {
        deName(e)
        {
            if(e)
            {
               return Global.Global_admission_status_obj[JSON.parse(e).admission_status+ '']
            }
        },
        linshidaochu(){
            let loadingInstance = ElLoading.service({
                lock: true,
                text: '正在生成文件',
            });
            //数据表格
            let exportList = this.tableData;
            let exportTitle = ['批次名称', '录取状态', '招生老师', '报名时间']
            let aoa = [];
            let dayinName = [
                'batch_name',
                'admission_status_name',
                'teacher_name',
                'created_at',
            ]
            this.tableTitle.forEach(item=>{
                if(item.show){
                    exportTitle.push(item.name)
                    if(item.type == 'image'){
                        dayinName.push(item[item.key+'_image'])
                    }
                    else{
                        dayinName.push(item.key)
                    }
                }
            })
            aoa.push(exportTitle)
            exportList.forEach(item => {
                let a = []
                dayinName.forEach(item2 => {
                    a.push(item[item2])
                })
                console.log(a);
                aoa.push(a)
            })
            //创建book
            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.aoa_to_sheet(aoa);
            //设置列宽
            ws['!cols'] = [];
            XLSX.utils.book_append_sheet(wb, ws, "sheet1");
            //输出
            let exportName = "学生档案.xlsx"
            XLSX.writeFile(wb, exportName);
            loadingInstance.close()
        },
        saveImport() {
            this.$refs.batchForm.validate((valid) => {
                if (valid) {
                    console.log(valid);
                    console.log(this.batchForm.ruleUploadList[0].raw);
                    utilsUploadFile(this.batchForm.ruleUploadList[0].raw, 'import').then(res => {
                        let url = '/admin/v1/sign_up/archives/supplement';
                        let data = {
                            file_id: res.file_id,
                        }
                        businessPost(url, data).then(res2 => {
                            if (res2.data.status == 200) {
                                utilsNotificationJump(res2.data.message, '/systemImport')
                                this.importStudentShow = false;
                                this.getTableData();
                            }
                            else {
                                this.$message({
                                    showClose: true,
                                    message: res2.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    })
                }
            })
        },
        openImport() {
            this.batchForm.ruleFileList = [];
            this.batchForm.ruleFileLimit = 1;
            this.batchForm.ruleUploadList = [];
            this.importStudentShow = true;
        },
        ruleHandleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 ` + this.batchForm.ruleFileLimit + ` 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        ruleHandleChange(file, fileList) {
            this.batchForm.ruleUploadList = fileList;
        },
        handleClick() {
            this.get_batch_no_list()
            this.search()
        },
        getChangeList1(){
            this.changeTableData = [];
            let url = `/admin/v1/operation?page=1&page_pre=100&Module=sign_archives&ltid=` + this.selMsg.id;
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    console.log("----------",res);
                    this.changeTableData = res.data.data.list;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        getChangeList() {
            this.changeTableData = [];
            let url = '/admin/v1/sign_up/major/change_log?page=1&page_pre=100&id_card=' + this.selMsg.id_card;
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    this.changeTableData = res.data.data.list;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },

        openChangeLog1(val)
        {
            this.selMsg = val;
            this.changeLogShow1 = true;
            this.getChangeList1();
        },
        openChangeLog(val) {
            this.selMsg = val;
            this.changeLogShow = true;
            this.getChangeList();
        },
        saveChangeBatch() {
            if (!this.student_batch_no) {
                this.$message({
                    showClose: true,
                    message: '请选择新批次名称',
                    type: 'warning'
                });
                return
            }
            this.$confirm('此操作将学生（' + this.selMsg.name + '）批次变更为 ' + this.new_batch_no_msg.label + ' , 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let url = '/admin/v1/sign_up/major/change'
                let data = {
                    archives_id: this.selMsg.id,
                    batch_no: this.student_batch_no
                }
                let loadingInstance = ElLoading.service()
                businessPost(url, data).then(res => {
                    setTimeout(() => {
                        loadingInstance.close();
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.changeBatchNoShow = false;
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    }, 500)
                })
            }).catch(() => {

            })
        },
        openChangeBatchNo(val) {
            this.selMsg = val;
            let canArray = []
            this.all_batch_no_list.forEach(item => {
                if (item.is_pre_inscription == this.selMsg.is_pre_inscription && this.selMsg.batch_no != item.value) {
                    canArray.push(item)
                }
            })
            this.can_batch_list = canArray;
            this.student_batch_no = '';
            this.changeBatchNoShow = true;
        },
        titlesChange() {
            let newArray = [];
            this.tableTitle.forEach(item => {
                if (item.show) {
                    newArray.push(item)
                }
            })
            localStorage.setItem(this.localName, JSON.stringify(newArray));
        },
        localTableTitle() {
            if (localStorage.getItem(this.localName)) {
                let oldShowTableTitles = JSON.parse(localStorage.getItem(this.localName));
                for (let i = 0; i < oldShowTableTitles.length; i++) {
                    this.tableTitle.filter((item, index) => {
                        if (oldShowTableTitles[i].key == item.key) {
                            this.tableTitle[index].show = true;
                        }
                    })
                }
            }
            else {
                this.tableTitle.forEach(item => {
                    item.show = true;
                })
                this.titlesChange();
            }
        },
        openExport() {
            let url = '/admin/v1/sign_up/archives?is_export=1';
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    if (k == 'start_end_time') {
                        if (this.searchForm[k][0] && this.searchForm[k][1]) {
                            url = url + '&year_start_end_time'  + '=' + this.searchForm[k][0] + " - "+ this.searchForm[k][1] ;
                        }
                    }
                    else {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
            }
            utilsExportExcel(url)
        },
        openAdmitted(sort, status) {
            let data = {
                admission_status: status
            };
            let admission_status_name = ''
            if (status == 1) {
                admission_status_name = '录取'
            }
            else if (status == 2) {
                admission_status_name = '拒绝录取'
            }
            else if (status == 5) {
                admission_status_name = '预录取'
            }
            if (sort == 'sel') {
                if (this.tableDataSel.length == 0) {
                    this.$message({
                        showClose: true,
                        message: '请先选择学生',
                        type: 'warning'
                    });
                    return
                }
                this.$confirm('此操作将勾选的学生' + admission_status_name + ', 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let archives_id_list = [];
                    this.tableDataSel.forEach(item => {
                        archives_id_list.push(item.id)
                    })
                    data.archives_id = archives_id_list.join(',')
                    this.admittedStudents(data)
                }).catch(() => {

                })
            }
            else if (sort == 'all') {
                if (this.searchForm.batch_no == '') {
                    this.$message({
                        showClose: true,
                        message: '请先选择批次',
                        type: 'warning'
                    });
                    return
                }
                this.$confirm('此操作当前批次的全部学生录取, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    data.batch_no = this.searchForm.batch_no;
                    this.admittedStudents(data)
                }).catch(() => {

                })
            }
        },
        admittedStudents(val) {
            let url = '/admin/v1/sign_up/archives/status';
            let data = val;
            let loadingInstance = ElLoading.service()
            businessPost(url, data).then(res => {
                loadingInstance.close();
                if (res.data.status == 200) {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'success'
                    });
                    this.getTableData();
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        saveEdit() {
            let data = this.$refs.customizeForm1.getFormData();
            if (!data) {
                return
            }
            let url = '/admin/v1/sign_up/archives/update/' + this.selMsg.id;
            businessPut(url, data).then(res => {
                if (res.data.status == 200) {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'success'
                    });
                    this.editDialog = false;
                    this.getTableData();
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        openEdit(val) {
            this.selMsg = val;
            let url = '/admin/v1/sign_up/archives/update/' + this.selMsg.id;
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    this.formMsg = res.data.data;
                    // let formMsg = res.data.data;
                    // formMsg.forEach(item => {
                    //     item.list.forEach(son => {
                    //         if (son.key == 'name' || son.key == 'id_card') {
                    //             son.disabled = true;
                    //         }
                    //     })
                    // })
                    this.editDialog = true;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
        stateEdit(val){

            console.log("000000000000",val)
        },
        tableMsgFromSon(event) {
            this.tableDataSel = event;
        },
        cleanAll() {
            this.searchForm.batch_no = '';
            this.searchForm.teacher_no = '';
            var year = moment().format("YYYY")
            this.searchForm.start_end_time = [`${year}-01-01`,`${year}-12-31`]
            this.searchForm.key_words = '';
            this.searchForm.admission_status = '';
            this.search();
        },
        search() {
            this.$refs.publicPageUserStudent.currentPage = 1;
            this.getTableData();
        },
        getTableData() {
            this.tableData = [];
            this.reductionTableTitle();
            let url = '/admin/v1/sign_up/archives?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
            for (let k in this.searchForm) {
                if (this.searchForm[k]) {
                    if (k == 'start_end_time') {
                        if (this.searchForm[k][0] && this.searchForm[k][1]) {
                            url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                        }
                    }
                    else {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    this.total = res.data.data.count;
                    let tableData = res.data.data.list;
                    tableData.forEach(item => {
                        item.admission_status_name = Global.Global_admission_status_obj[item.admission_status + '']
                    })
                    this.tableData = tableData;
                }
            })
        },
        reductionTableTitle() {
            let tableTitle = [];
            if (this.searchForm.batch_no == '') {
                this.allTitleList.forEach(item => {
                    let one = JSON.parse(JSON.stringify(item))
                    one.show = false
                    tableTitle.push(one)
                })
                this.tableTitle = tableTitle;
                this.localTableTitle()
            }
            else {
                let weekChMsg = this.batch_no_list.filter(item => {
                    return this.searchForm.batch_no == item.value
                })
                let fieldsList = weekChMsg[0].fields.split(',')
                this.allTitleList.forEach(item => {
                    if (fieldsList.indexOf(item.id + '') != -1) {
                        let one = JSON.parse(JSON.stringify(item))
                        one.show = true;
                        tableTitle.push(one)
                    }
                })
                this.tableTitle = tableTitle;
            }
        },
        getTableTitle() {
            let url = '/admin/v1/sign_up/header';
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    this.allTitleList = res.data.data;
                    this.cleanAll();
                }
            })
        },
        get_batch_no_list() {
            let newArray = [];
            this.all_batch_no_list.forEach(item => {
                if (item.is_pre_inscription == this.searchForm.is_pre_inscription) {
                    newArray.push(item)
                }
            })
            this.batch_no_list = newArray;
            this.searchForm.batch_no = '';
        },
        get_all_batch_no_list() {
            let url = '/admin/v1/sign_up?type=1&page=1&page_pre=500';
            businessGet(url).then(res => {
                if (res.data.status == 200) {
                    let newArray = [];
                    res.data.data.list.forEach(item => {
                        let a = {
                            value: item.batch_no,
                            label: item.batch_name,
                            fields: item.fields,
                            is_pre_inscription: item.is_pre_inscription,
                            amount: item.amount
                        }
                        newArray.push(a);
                    })
                    this.all_batch_no_list = newArray;
                    this.get_batch_no_list();
                    // setTimeout(() => {
                    //     if (this.batch_no_list.length > 0) {
                    //         this.getTableTitle();
                    //     }
                    // }, 500)
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        }
    }
}
</script>

<style scoped>

@import "../../assets/css/base.css";

.userTeacherML {
    margin-left: 16px;
}

.signupProcessDrawerForm {
    width: 800px;
    margin: 20px auto;
}

</style>
